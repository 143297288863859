<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                        <q-btn icon-right="send" color="danger" square no-caps :to="{name: 'Register'}" type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('register') }}</q-btn>
                        <q-btn icon-right="login" color="dark" square no-caps :to="{name: 'Login'}" type="button" class="ml-1 no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <b-form>
                        <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{ translate('activate_account') }}</h1>
                        <p class="text-center text-muted">{{ translate('activation_here') }}</p>

                        <b-input-group class="mb-3">
                            <q-input light square dense outlined :error-message="translate('password_is_required')" no-error-icon :error="submitted && $v.newUser.password.$error" color="primary" type="password" v-model="newUser.password" :label="translate('password')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                            <div class="pl-3 text-muted" :class="{'mt-3': submitted && $v.newUser.password.$error}">
                                {{ translate('please_enter_password_with_limitations') }}
                            </div>
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <q-input light square dense outlined no-error-icon :error="submitted && $v.newUser.password_confirmation.$error" color="primary" type="password" v-model="newUser.password_confirmation" :label="translate('confirm_password')" :error-message="!$v.newUser.password_confirmation.required ? translate('confirm_password_is_required') : translate('confirm_password_must_match')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                        </b-input-group>

                        <b-input-group v-if="msg" class="mb-3">
                            <q-item class="bg-red-2 border-top-red">
                                <q-item-section class="is-invalid text-negative">{{ msg }}</q-item-section>
                            </q-item>
                        </b-input-group>

                        <div class="text-center">
                            <q-btn @click.prevent="activateUser()" class="mt-2 icon-right-leftspace" icon-right="send" color="success" square no-caps type="submit">{{ translate('activate') }}</q-btn>
                        </div>
                    </b-form>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import LangNavbarAuth from './LangNavbarAuth'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import _ from 'lodash'

export default {
    name: 'ActivationWorkerAccount',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    data: function () {
        return {
            msg: null,
            submitted: false,
            newUser: {
                password: null,
                confirmation_password: null
            },
        }
    },
    validations: {
        newUser: {
            password: {
                required
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    methods: {
        activateUser: function () {
            this.submitted = true
            if (!this.$v) {
                return
            }
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            var email = this.$route.query['email']
            var params = {
                email: email,
                activation_token: this.$route.query['activation_token'],
                password: this.newUser.password
            }
            var self = this
            axios.post(baseUrl + 'auth/activate-worker-user', params)
                .then(response => {
                    var message = 'activate_worker_account_' + response.data.msg
                    this.msg = message
                    if (this.submit === 'no') {
                        this.submit = 'yes'
                    }
                    if (response.data && response.data.msg === 'resource_activated') {
                        self.$router.push({name: 'CreatedAccount'}, undefined, () => { 
                            location.href = this.$route.hash 
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }
}
</script>
